import http from "@/apps/shared/baseService/config";

const base = {
  rota: "external_movimentations",
  nome: "Visitas/Entregas"
};

export const service = {
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  listar_my_external_movimentations: data =>
    http.post(`/admin/${base.rota}/list-my-movimentations`, data)
};

const module = {
  actions: {
    listar_external_movimentations: ({ commit, dispatch, getters }) => {
      // if (localStorage.getItem("external_movimentations")) {
      //   commit(
      //     "set_external_movimentations",
      //     JSON.parse(localStorage.getItem("external_movimentations"))
      //   );
      // } else {
      // }
      dispatch("enableLoading");
      service
        .listar(getters.get_external_movimentations_filtros)
        .then(res => {
          dispatch("disableLoading");
          localStorage.setItem(
            "external_movimentations",
            JSON.stringify(res.data)
          );
          commit("set_external_movimentations", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    listar_my_external_movimentations: ({ commit, dispatch, getters }) => {
      if (localStorage.getItem("my_external_movimentations")) {
        commit(
          "set_my_external_movimentations",
          JSON.parse(localStorage.getItem("my_external_movimentations"))
        );
      } else {
        dispatch("enableLoading");
      }
      service
        .listar_my_external_movimentations({})
        .then(res => {
          dispatch("disableLoading");
          localStorage.setItem(
            "my_external_movimentations",
            JSON.stringify(res.data)
          );
          commit("set_my_external_movimentations", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    close_view_external_movimentation: ({ commit }) => {
      commit("set_external_movimentation", {
        visit_date: null,
        type: "",
        address_destiny: null,
        visit_hour: "",
        executor: null,
      });
    }
  },
  state: {
    external_movimentation: {
      visit_date: null,
      type: "",
      address_destiny: null,
      visit_hour: "",
      executor: null,
    },
    external_movimentations: [],
    my_external_movimentations: [],
    external_movimentations_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
  },
  mutations: {
    set_external_movimentation: (state, payload) =>
      (state.external_movimentation = payload),
    set_external_movimentations: (state, payload) =>
      (state.external_movimentations = payload),
    set_my_external_movimentations: (state, payload) =>
      (state.my_external_movimentations = payload),
    set_external_movimentations_filtros: (state, payload) =>
      (state.external_movimentations_filtros = payload)
  },
  getters: {
    get_external_movimentation: state => state.external_movimentation,
    get_external_movimentations: state => state.external_movimentations,
    get_external_movimentations_filtros: state =>
      state.external_movimentations_filtros,
    get_my_external_movimentations: state => state.my_external_movimentations,
  }
};

const routes = [
  {
    path: `/agenda`,
    component: () => import("./views/Main.vue"),
    name: "Agenda"
  },
  {
    path: `/minha-agenda`,
    component: () => import("./views/MyExternalTasks.vue"),
    name: "Minha Agenda"
  }
];

export default {
  service,
  module,
  routes
};
