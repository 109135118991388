class Helper {
  formataSaldo(s) {
    let saldo = s || 0;
    const saldo_convert = parseFloat(saldo);
    return saldo_convert.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }
  validateField(val) {
    console.log("asdadsadadas");
    if (val) {
      return val;
    } else {
      return "";
    }
  }
}

export default Helper;
